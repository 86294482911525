<template>
  <b-modal
    ref="create-group-modal"
    centered
    size="sm"
    hide-footer
    hide-header
    class="position-relative"
  >
    <div
      class="create-group-modal d-flex justify-content-center align-content-center align-items-center m-1 mb-2"
    >
      <b-row class="d-block w-75">
        <div class="closediv mr-1 mt-1">
          <b-button
            variant="transparent"
            @click="hide()"
          >
            <feather-icon
              icon="XIcon"
              class="cursor-pointer"
              size="16"
            />
          </b-button>
        </div>
        <div
          v-if="editGroupModal"
          class="d-inline-block d-flex justify-content-center align-content-center align-items-center mb-2"
        >
          <span class="font-weight-bolder heading1">  Edit Group </span>
        </div>
        <div
          v-else
          class="d-inline-block d-flex justify-content-center align-content-center align-items-center mb-2"
        >
          <span class="font-weight-bolder heading1">  Create Group </span>
        </div>
        <div class="d-flex justify-content-center">
          <div class="form w-100">
            <div
              class="d-inline-block d-flex justify-content-center align-content-center align-items-center mt-1 ml-1"
            >
              <b-img
                :src="
                  groupImage
                    ? groupImage
                    : require('@/assets/images/simiicons/signup.svg')
                "
                alt="data.json"
                rounded="circle"
                :class="groupImage ? 'group-image' : null"
              />
              <input
                id="fileUpload"
                type="file"
                accept="image/*"
                hidden
                @change="imageDropped($event)"
              >
              <span class="upload">
                <b-button
                  variant="link"
                  class="icon-class"
                  @click="inputFileClicked"
                >
                  <b-img
                    :src="require('@/assets/images/simiicons/signupbtn.svg')"
                    rounded
                    alt="data.json"
                  />
                </b-button>
              </span>
            </div>
            <b-form-group
              label="Group Name"
              class="input-label w-100"
            >
              <b-form-input
                v-model="groupPayload.name"
                class="d-block"
                name="group-name"
                placeholder="Enter group name"
              />
            </b-form-group>
            <div class="d-flex justify-content-around">
              <div class="w-100 mr-1">
                <b-button
                  block
                  variant="secondary"
                  @click="hide"
                >
                  Cancel
                </b-button>
              </div>
              <div
                v-if="editGroupModal"
                class="w-100"
              >
                <b-button
                  block
                  variant="primary"
                  :disabled="!active"
                  @click="updateGroupData"
                ><div
                  v-if="spinner"
                  class="spinner"
                >
                  <b-spinner
                    small
                  />
                  <span class="sr-only">Loading...</span>
                </div>
                  Update
                </b-button>
              </div>
              <div
                v-else
                class="w-100"
              >
                <b-button
                  block
                  variant="primary"
                  @click="submitGroupData"
                >
                  Next
                </b-button>
              </div>
            </div>
          </div>
        </div>
      </b-row>
    </div>
  </b-modal>
</template>
<script>
import {
  BButton,
  BFormGroup,
  BFormInput,
  BImg,
  BModal,
  BRow,
  BSpinner,
} from 'bootstrap-vue'
import { getFormDataFromJSON, showToast } from '@/common/global/functions'

export default {
  name: 'CreateGroupModal',
  components: {
    BRow,
    BButton,
    BModal,
    BImg,
    BFormGroup,
    BFormInput,
    BSpinner,
  },
  props: {
    editGroupModal: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      typesOfImage: ['image/jpeg', 'image/png', 'image/jpg'],
      active: true,
      spinner: false,
      groupImage: null,
      groupPayload: {
        id: null,
        image: null,
        name: null,
      },
      name: null,
      image: null,
    }
  },
  computed: {
    preSelectedGroupData() {
      return this.$store.getters['user/getCreateGroupData']
    },
  },
  // watch: {
  //   editGroupDetailProps: { // watch it
  //     handler(newVal) {
  //       console.log(newVal)
  //       // this will be run immediately on component creation.
  //     },
  //     // force eager callback execution
  //     immediate: true,
  //   },
  // },
  methods: {
    show(obj) {
      this.groupPayload.id = obj?.id
      this.groupPayload.name = obj?.name
      this.groupImage = obj?.image
      this.$refs['create-group-modal'].show()
      // this.groupPayload = {
      //   image: null,
      //   name: null,
      // }
    },
    hide() {
      this.$refs['create-group-modal'].hide()
    },
    inputFileClicked() {
      document.getElementById('fileUpload').click()
    },
    imageDropped(event) {
      const obj = event.target.files[0]
      const { size, type } = obj
      if (this.typesOfImage.includes(type)) {
        if (size < '26214400 ') {
          this.groupPayload.image = obj
          this.groupImage = URL.createObjectURL(obj)
        } else {
          showToast(this.editGroupModal ? 'Edit Group' : 'Create Group', 'Max size of file should be 25MB!', 'danger', 4000)
          this.groupPayload.image = null
        }
      } else {
        showToast(this.editGroupModal ? 'Edit Group' : 'Create Group', 'Please upload Image having type jpeg, png, jpg!', 'danger', 4000)
        this.groupPayload.image = null
      }
    },
    submitGroupData() {
      if (!this.groupPayload.name) {
        showToast('Create Group', 'Please enter group name!', 'danger', 4000)
        return
      }
      this.hide()
      this.$store.commit('user/SET_CREATE_GROUP_DATA', { ...this.groupPayload, ...this.preSelectedGroupData })
      this.$router.push('/checkout')
    },
    async updateGroupData() {
      if (!this.groupPayload.name) {
        showToast('Edit Group', 'Please enter group name!', 'danger', 4000)
        return
      }
      this.spinner = true
      this.active = false
      await this.$axios.put('/user-has-group/update-group-details', getFormDataFromJSON(this.groupPayload)).then(() => {
        showToast('Edit Group', 'Group details edited successfully!')
        this.$store.dispatch('user/getUserIfNotExist', true)
        this.$store.dispatch('user/setAllUserGroupIfNotPresent', true)
        this.spinner = false
        this.active = true
        this.hide()
      }).catch(() => {
        showToast('Edit Group', 'Something went wrong!', 'danger', 4000)
      })
    },
  },
}
</script>

<style scoped lang="scss">
.create-group-modal {
  .group-image {
    width: 140px;
    height: 140px;
  }
  .icon-class {
    padding: 0 !important;
  }
  .upload {
    margin-left: -30px;
    margin-bottom: -40px;
  }
}
</style>
