<template>
  <section
    v-if="Object.values(getAllPackagesAvailable)"
    id="pricing-plan"
  >
    <!-- title text and switch button -->
    <div class="text-center mt-5">
      <h1 class="mt-5">
        Pricing Plans
      </h1>
      <p class="mb-2 pb-75">
        All plans include 40+ advanced tools and features to boost your product. Choose the best plan to fit your needs.
      </p>
      <div class="d-flex align-items-center justify-content-center mb-2">
        <h6 class="mr-1 mb-0 font-weight-bolder">
          Monthly
        </h6>
      </div>
    </div>
    <!--/ title text and switch button -->
    <!-- pricing plan cards -->
    <b-row class="pricing-card">
      <b-col
        offset-sm-2
        sm="10"
        md="12"
        offset-lg="2"
        lg="10"
        class="mx-auto"
      >
        <b-row>
          <b-row class="d-flex justify-content-center">
            <span
              v-for="(fullPackage, index ) in Object.values(getAllPackagesAvailable)"
              :key="fullPackage.name"
              :class="loader ? 'mr-3 mb-3': null"
            >
              <b-skeleton-img
                v-if="loader"
                no-aspect
              />
              <b-card
                v-else
                align="center"
                class="m-1"
              >
                <!-- img -->
                <b-img
                  src="@/assets/images/Pot1.svg"
                  class="mb-1"
                  alt="basic svg img"
                />
                <!--/ img -->
                <h3 class="mb-0">{{ fullPackage.name }}</h3>
                <b-card-text>
                  {{ fullPackage.description }}
                </b-card-text>

                <!-- annual plan -->
                <div class="annual-plan">
                  <div class="plan-price mt-2">
                    <sup class="font-medium-1 font-weight-bold text-primary">$</sup>
                    <span class="pricing-basic-value font-weight-bolder text-primary">{{ totalPrice && totalPrice[index] ? totalPrice[index] : fullPackage.price }}</span>
                    <sub class="pricing-duration text-body font-medium-1 font-weight-bold">/month</sub>
                  </div>
                </div>
                <!--/ annual plan -->

                <!-- plan benefit -->
                <b-list-group class="list-group-circle text-left">
                  <b-list-group-item
                    v-for="(data,i) in fullPackage.plan_has_feature"
                    :key="i"
                  >
                    {{ filterFeatureName(data.feature.name, data.quantity) }}
                  </b-list-group-item>
                  <b-list-group-item v-if="hasLauncherFeature(fullPackage.plan_has_feature)">
                    Profiles
                  </b-list-group-item>
                </b-list-group>
                <!--/ plan benefit -->
                <!--sub members-->
                <div class="position-bottom-0 position-absolute btns-class">
                  <div>
                    <b-dropdown
                      v-model="selected[index]"
                      v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                      :text="selected[index] ? selected[index] : 'Sub members'"
                      variant="outline-secondary"
                      menu-class="w-100"
                      right
                      block
                    >
                      <span
                        v-for="option in options"
                        :key="option.key"
                      >
                        <b-dropdown-divider />

                        <b-dropdown-item @click="setValueOfMembers(index, option.value, option.key, fullPackage.price, fullPackage.member_price, fullPackage.name,fullPackage.description, fullPackage.plan_has_feature)">
                          {{ option.key }}
                        </b-dropdown-item>
                      </span>
                    </b-dropdown>
                  </div>
                  <strong class="d-none">{{ group.quota }}</strong>
                  <!-- /sub members-->
                  <!-- buttons -->
                  <div>
                    <b-button
                      v-ripple.400="'rgba(40, 199, 111, 0.15)'"
                      block
                      class="mt-2"
                      variant="primary"
                      :disabled="spinner"
                      @click="selectPackageFunc(fullPackage.id, index)"
                    >
                      <div
                        v-if="spinner"
                        class="spinner"
                      >
                        <b-spinner
                          small
                        />
                        <span class="sr-only">Loading...</span>
                      </div>
                      Select
                    </b-button>
                  </div>
                </div>
              </b-card>
            </span>
          </b-row>
        </b-row>
      </b-col>
    </b-row>
    <!--/ pricing plan cards -->
    <create-group-modal
      ref="create-group-modal"
      :edit-group-modal="editGroupModal"
    />
  </section>
</template>

<script>
import {
  BRow, BCol, BCard, BImg, BCardText, BListGroup, BListGroupItem, BButton, BDropdown, BDropdownItem, BDropdownDivider, BSpinner, BSkeletonImg,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import store from '@/store'
import SiteType from '@/common/enums/siteTypeEnum'
/* eslint-disable global-require */
import ApplicationType from '@/common/enums/applicationTypeEnum'
import CreateGroupModal from '@/views/groups/CreateGroupModal.vue'
import { showToast } from '@/common/global/functions'

export default {
  components: {
    CreateGroupModal,
    BButton,
    BCardText,
    BListGroup,
    BListGroupItem,
    BRow,
    BCol,
    BCard,
    BImg,
    BDropdown,
    BDropdownItem,
    BDropdownDivider,
    BSpinner,
    BSkeletonImg,
  },
  directives: {
    Ripple,
  },
  async beforeRouteEnter(to, from, next) {
    // const userOwnGroup = await store.getters['user/getUserOwnCreatedGroup']
    const user = await store.getters['user/getUser']
    const isUserAdmin = await store.getters['user/isUserAdmin']
    if (isUserAdmin) {
      next({
        path: '/',
      })
      return
    }
    if (!isUserAdmin && !user.is_phone_number_verified) {
      next({
        name: 'phone-verification',
      })
      return
    }
    if (!isUserAdmin && !user.is_profile_completed) {
      next({
        name: 'complete-profile',
      })
      return
    }
    // if (!isUserAdmin && userOwnGroup) {
    //   next({
    //     name: 'groups-listing',
    //   })
    //   return
    // }

    next()
  },

  data() {
    return {
      SiteType,
      loader: false,
      spinner: false,
      selected: [],
      status: 'monthly',
      monthlyPlanShow: true,
      options: [{ key: 5, value: 'No of Members 5' }, { key: 10, value: 'No of Members 10' }, { key: 25, value: 'No of Members 25' }, { key: 50, value: 'No of Members 50' }, { key: 75, value: 'No of Members 75' }, { key: 100, value: 'No of Members 100' }],
      memberValue: [],
      totalPrice: null,
      group: {
        planId: null,
        quota: null,
        price: null,
        packageName: '',
        features: [],
        description: null,
      },
      editGroupModal: false,
    }
  },
  computed: {
    getAllPackagesAvailable() {
      return this.$store.getters['user/getAllPackagesAvailable']
    },
  },
  async mounted() {
    this.loader = true
    await this.$store.dispatch('user/setPackagesAvailable')
    this.loader = false
  },
  methods: {
    openConfirmationModal() {
      this.$refs['create-group-modal'].show()
    },
    setValueOfMembers(index, value, key, price, memberPrice, name, description, features) {
      this.selected = []
      this.totalPrice = []
      this.selected[index] = value
      this.group.quota = key
      this.totalPrice[index] = price + memberPrice * key
      this.group.price = this.totalPrice[index]
      this.group.packageName = name
      this.group.features = features
      this.group.description = description
    },
    selectPackageFunc(id, index) {
      if (this.selected[index] && this.group.quota !== null) {
        this.spinner = true
        this.group.planId = id
        this.openConfirmationModal()
        this.$store.commit('user/SET_CREATE_GROUP_DATA', this.group)
        this.spinner = false
      } else {
        this.selected = []
        showToast('Pricing Plans', 'Please select no. of members!', 'danger', 4000)
      }
    },
    filterFeatureName(name, quantity) {
      if (name === 'Site' && quantity === SiteType.SITE) {
        return 'Site'
      }
      if (name === 'Site' && quantity === SiteType.MULTI_SITE) {
        return 'Multi Site'
      }
      return name
    },
    hasLauncherFeature(features) {
      let found = false
      found = features.some(el => el.feature.application_type === ApplicationType.SIMI_LAUNCHER)
      return found
    },
  },
}
/* eslint-disable global-require */
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-pricing.scss';
#pricing-plan{
  .dropdown-item {
    padding: 0.05rem 1.28rem !important;
  }
  .card-body{
    margin: 1rem !important;
    padding: 1rem !important;
    position: relative;
    margin-top: 0px !important;
  }
  .card{
    min-height:550px !important;
    min-width: 300px !important;
  }
  .b-skeleton-img{
    min-height:450px !important;
    min-width: 300px !important;
  }
  .row{
    margin: 0px !important;
  }
  .btns-class{
    width: 100%;
    margin: -15px;
    margin-bottom: 15px;
  }
   .list-group-item{
     padding: 0.5rem .25rem !important;
     padding-left: 1.5rem
   }
 .list-group.list-group-circle .list-group-item{
    padding-left: 1.5rem !important;
  }
}
</style>
